import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button } from 'semantic-ui-react';

import FormInput from '../FormInput';

// NOTE: cloneDeep is needed in the _.set, _unset below to
// not break shouldComponentUpdate in other components

class FormInputMap extends React.Component {
  static propTypes = {
    fields: PropTypes.object,
    className: PropTypes.string,
    handleUpdate: PropTypes.func.isRequired,
    noAdd: PropTypes.bool,
    noUnset: PropTypes.bool, // don't unset value if empty fields array (just set to empty array)
    renderFactory: PropTypes.func,
    size: PropTypes.string,
  };

  state = {
    externalFields: '',
    fields: [],
  };

  componentWillMount() {
    if (!_.isEmpty(this.props.fields)) {
      this.onExternalValueChange(this.props, this.state);
    }
  }

  componentWillUpdate(nextProps, nextState) {
    this.onExternalValueChange(nextProps, nextState);
  }

  convertExternalValue = (externalFields = {}) => {
    const fields = [];

    if (externalFields) {
      _.map(externalFields, (value, name) => {
        if (typeof value === 'object') {
          fields.push({
            name,
            ...value,
          });
        } else {
          fields.push({
            name,
            value,
          });
        }
      });
    }

    return fields;
  };

  onExternalValueChange = (props, state) => {
    if (state.internal) {
      this.setState({
        internal: false,
      });
    } else if (!_.isEqual(props.fields, state.externalFields)) {
      this.setState({
        externalFields: props.fields,
        fields: this.convertExternalValue(props.fields),
      });
    }
  };

  onChange = fields => {
    const externalFields = {};
    for (const i of fields) {
      const { name, ...extra } = i;
      if (_.size(extra) <= 1 && extra.hasOwnProperty('value')) {
        externalFields[i.name] = extra.value || '';
      } else {
        externalFields[name] = extra;
      }
    }

    this.setState({
      internal: true,
      externalFields,
      fields,
    });

    if (!this.props.noUnset && _.isEmpty(externalFields)) {
      this.props.handleUpdate('unset', []);
    } else {
      this.props.handleUpdate('set', [], externalFields);
    }
  };

  render() {
    const {
      className = '',
      addText = 'Add',
      namePlaceholder = '',
      valuePlaceholder = '',
      meta = {},
      renderFactory,
      noAdd,
      readOnly,
      size,
    } = this.props;

    let contentElem;
    if (_.isEmpty(this.state.fields) && renderFactory) {
      contentElem = <div className="c-muted">none</div>;
    } else if (renderFactory) {
      contentElem = renderFactory({
        fields: this.state.fields,
        onChange: (t, p, v) => {
          const fields = _.cloneDeep(this.state.fields);

          switch (t) {
            case 'set':
              _.set(fields, p, v);
              break;
            case 'unset':
              _.unset(fields, p);
              break;
          }

          this.onChange(fields);
        },
        onRemove: index => {
          const fields = _.cloneDeep(this.state.fields);
          _.pullAt(fields, index);
          this.onChange(fields);
        },
      });
    } else {
      contentElem = this.state.fields.map((field, index) => {
        return (
          <div key={index} className="mt-3">
            <div className="flex items-center">
              <FormInput
                id={`FormInputMapKey-${index}`}
                input={{
                  value: field.name,
                  onChange: (e, { value }) => {
                    const fields = _.cloneDeep(this.state.fields);
                    _.set(fields, [index, 'name'], value);
                    this.onChange(fields);
                  },
                }}
                disabled={meta.submitting}
                placeholder={namePlaceholder}
                className="flex-1 mr-2"
                readOnly={readOnly}
                size={size}
                fluid
              />

              <b>:</b>

              <FormInput
                id={`FormInputMapValue-${index}`}
                input={{
                  value: field.value,
                  onChange: (e, { value }) => {
                    const fields = _.cloneDeep(this.state.fields);
                    _.set(fields, [index, 'value'], value);
                    this.onChange(fields);
                  },
                }}
                disabled={meta.submitting}
                placeholder={valuePlaceholder}
                className="flex-1 ml-2"
                readOnly={readOnly}
                size={size}
                fluid
              />
              {readOnly ? null : (
                <div className="ml-3">
                  <Button
                    icon="trash"
                    size={size}
                    onClick={() => {
                      const fields = _.cloneDeep(this.state.fields);
                      _.pullAt(fields, index);
                      this.onChange(fields);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        );
      });
    }

    return (
      <div className={`FormInputList ${className}`}>
        {noAdd || readOnly ? null : (
          <Button
            onClick={() => {
              const fields = _.cloneDeep(this.state.fields);
              fields.push({ name: '', value: '' });
              this.onChange(fields);
            }}
            basic
            disabled={readOnly}
            size={size}
          >
            {addText}
          </Button>
        )}

        {contentElem}
      </div>
    );
  }
}

export default FormInputMap;
