import React from 'react';

import PropTypes from 'prop-types';

// import './styles.styl';

/**
 * Returns the type of an object as a string.
 *
 * @param obj Object The object you want to inspect
 * @return String The object's type
 */
const objType = obj => {
  const className = Object.prototype.toString.call(obj).slice(8, -1);
  return className;
};

/**
 * String node component
 */
const JSONStringNode = ({ data, keyName, value }) => {
  return (
    <li
      className="string itemNode"
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <label>{keyName}:</label>
      <span>{value}</span>
    </li>
  );
};

/**
 * Number node component
 */
const JSONNumberNode = ({ data, keyName, value }) => {
  return (
    <li
      className="number itemNode"
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <label>{keyName}:</label>
      <span>{value}</span>
    </li>
  );
};

/**
 * Null node component
 */
const JSONNullNode = ({ data, keyName, value }) => {
  return (
    <li
      className="null itemNode"
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <label>{keyName}:</label>
      <span>null</span>
    </li>
  );
};

/**
 * Boolean node component
 */
const JSONBooleanNode = ({ data, keyName, value }) => {
  const truthString = value ? 'true' : 'false';
  return (
    <li
      className="boolean itemNode"
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <label>{keyName}:</label>
      <span>{truthString}</span>
    </li>
  );
};

/**
 * Creates a React JSON Viewer component for a key and it's associated data
 *
 * @param key String The JSON key (property name) for the node
 * @param value Mixed The associated data for the JSON key
 * @return Component The React Component for that node
 */
const grabNode = (key, value) => {
  const nodeType = objType(value);
  let theNode;
  const aKey = key + Date.now();
  if (nodeType === 'Object') {
    theNode = <JSONObjectNode data={value} keyName={key} key={aKey} />;
  } else if (nodeType === 'Array') {
    theNode = <JSONArrayNode data={value} keyName={key} key={aKey} />;
  } else if (nodeType === 'String') {
    theNode = <JSONStringNode keyName={key} value={value} key={aKey} />;
  } else if (nodeType === 'Number') {
    theNode = <JSONNumberNode keyName={key} value={value} key={aKey} />;
  } else if (nodeType === 'Boolean') {
    theNode = <JSONBooleanNode keyName={key} value={value} key={aKey} />;
  } else if (nodeType === 'Null') {
    theNode = <JSONNullNode keyName={key} value={value} key={aKey} />;
  } else {
    console.error('How did this happen?', nodeType);
  }
  return theNode;
};

/**
 * Mixin for setting intial props and state and handling clicks on
 * nodes that can be expanded.
 */
function expandedStateHandlerHOC(Component) {
  return class extends React.Component {
    static defaultProps = {
      data: [],
      initialExpanded: false,
    };

    state = {
      expanded: this.props.initialExpanded,
    };

    handleClick = e => {
      e.stopPropagation();
      this.setState({
        expanded: !this.state.expanded,
      });
    };

    componentWillReceiveProps() {
      // resets our caches and flags we need to build child nodes again
      this.renderedChildren = [];
      this.itemString = false;
      this.needsChildNodes = true;
    }

    render() {
      return <Component handleClick={this.handleClick} {...this.props} {...this.state} />;
    }
  };
}

const buildChildNodes = ({ data }) => {
  const childNodes = [];

  const obj = data;
  for (const k in obj) {
    if (obj.hasOwnProperty(k)) {
      childNodes.push(grabNode(k, obj[k]));
    }
  }

  return childNodes;
};

/**
 * Array node class. If you have an array, this is what you should use to
 * display it.
 */
@expandedStateHandlerHOC
class JSONArrayNode extends React.Component {
  static displayName = 'JSONArrayNode';

  static propTypes = {
    data: PropTypes.array,
    keyName: PropTypes.node,
    expanded: PropTypes.bool,
    handleClick: PropTypes.func,
  };

  /**
   * Returns the child nodes for each element in the array. If we have
   * generated them previously, we return from cache, otherwise we create
   * them.
   */
  getChildNodes = () => {
    if (this.props.expanded && this.needsChildNodes) {
      const childNodes = buildChildNodes({ data: this.props.data });
      this.needsChildNodes = false;
      this.renderedChildren = childNodes;
    }

    return this.renderedChildren || [];
  };

  /**
   * flag to see if we still need to render our child nodes
   */
  needsChildNodes = true;

  /**
   * cache store for our child nodes
   */
  renderedChildren = [];

  /**
   * cache store for the number of items string we display
   */
  itemString = false;

  /**
   * Returns the "n Items" string for this node, generating and
   * caching it if it hasn't been created yet.
   */
  getItemString = () => {
    if (!this.itemString) {
      const lenWord = this.props.data.length === 1 ? ' Item' : ' Items';
      this.itemString = this.props.data.length + lenWord;
    }
    return this.itemString;
  };

  render() {
    const childNodes = this.getChildNodes();
    const childListStyle = {
      display: this.props.expanded ? 'block' : 'none',
    };
    let cls = 'array parentNode';
    cls += this.props.expanded ? ' expanded' : '';
    return (
      <li className={cls} onClick={this.props.handleClick}>
        <label>{this.props.keyName}</label>
        <span>{this.getItemString()}</span>
        <ol style={childListStyle}>{childNodes}</ol>
      </li>
    );
  }
}

/**
 * Object node class. If you have an object, this is what you should use to
 * display it.
 */
@expandedStateHandlerHOC
class JSONObjectNode extends React.Component {
  static displayName = 'JSONObjectNode';

  static propTypes = {
    data: PropTypes.object,
    keyName: PropTypes.node,
    expanded: PropTypes.bool,
    handleClick: PropTypes.func,
  };

  /**
   * Returns the child nodes for each element in the object. If we have
   * generated them previously, we return from cache, otherwise we create
   * them.
   */
  getChildNodes = () => {
    if (this.props.expanded && this.needsChildNodes) {
      const childNodes = buildChildNodes({ data: this.props.data });
      this.needsChildNodes = false;
      this.renderedChildren = childNodes;
    }

    return this.renderedChildren || [];
  };

  /**
   * Returns the "n Items" string for this node, generating and
   * caching it if it hasn't been created yet.
   */
  getItemString = () => {
    if (!this.itemString) {
      const obj = this.props.data;
      let len = 0;
      let lenWord = ' Items';
      for (const k in obj) {
        if (obj.hasOwnProperty(k)) {
          len += 1;
        }
      }
      if (len === 1) {
        lenWord = ' Item';
      }
      this.itemString = len + lenWord;
    }
    return this.itemString;
  };

  /**
   * cache store for the number of items string we display
   */
  itemString = false;

  /**
   * flag to see if we still need to render our child nodes
   */
  needsChildNodes = true;

  /**
   * cache store for our child nodes
   */
  renderedChildren = [];

  render() {
    const childNodes = this.getChildNodes();
    const childListStyle = {
      display: this.props.expanded ? 'block' : 'none',
    };
    let cls = 'object parentNode';
    cls += this.props.expanded ? ' expanded' : '';
    return (
      <li className={cls} onClick={this.props.handleClick}>
        <label>{this.props.keyName}:</label>
        <span>{this.getItemString()}</span>
        <ul style={childListStyle}>{childNodes}</ul>
      </li>
    );
  }
}

/**
 * JSONTree component. This is the 'viewer' base. Pass it a `data` prop and it
 * will render that data, or pass it a `source` URL prop and it will make
 * an XMLHttpRequest for said URL and render that when it loads the data.
 *
 * The first node it draws will be expanded by default.
 */
class JSONTree extends React.Component {
  static propTypes = {
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  };

  static defaultProps = {
    source: false,
  };

  render() {
    let { data } = this.props;
    if (typeof data === 'string') {
      try {
        data = JSON.parse(data);
      } catch (e) {
        // error
      }
    }

    const nodeType = objType(data);
    let rootNode;
    if (nodeType === 'Object') {
      rootNode = <JSONObjectNode data={data} keyName="object" initialExpanded />;
    } else if (nodeType === 'Array') {
      rootNode = <JSONArrayNode data={data} initialExpanded keyName="array" />;
    } else {
      console.error('How did you manage that?');
    }
    return <ul className="sl-json-tree">{rootNode}</ul>;
  }
}

export default JSONTree;
