import React from 'react';
import _ from 'lodash';

import FormCodeEditor from '../FormCodeEditor';
import FormInputMap from '../FormInputMap';
import FormRadio from '../FormRadio';
import FormDropdown from '../FormDropdown';

const FormHttpBodyEditor = ({ id, className = '', input = {}, handleUpdate, readOnly }) => {
  const handleUpdateContentType = (updateStep, headers = {}, contentType) => {
    const newHeaders = _.map(headers, (value, name) => ({ name, value }));
    const contentTypeIndex = _.findIndex(newHeaders, val => {
      return val.name.toLowerCase() === 'content-type';
    });

    if (contentTypeIndex >= 0) {
      let setHeaders = false;
      if (contentType) {
        newHeaders[contentTypeIndex].value = contentType;
        setHeaders = true;
      } else if (newHeaders.length === 1) {
        updateStep('unset', ['input', 'headers']);
      } else {
        _.pullAt(newHeaders, contentTypeIndex);
        setHeaders = true;
      }

      if (setHeaders) {
        const headerMap = {};
        for (const h of newHeaders) {
          headerMap[h.name] = h.value;
        }
        updateStep('set', ['input', 'headers'], headerMap);
      }
    } else if (contentType) {
      updateStep('set', ['input', 'headers'], {
        ...headers,
        'Content-Type': contentType,
      });
    }
  };

  const headers = input.headers || {};
  const contentType = headers['content-type'] || headers['Content-Type'];
  const paramContentTypes = ['application/x-www-form-urlencoded', 'multipart/form-data'];

  let raw;
  if (!_.includes(paramContentTypes, contentType)) {
    raw = true;
  }

  let mode = 'text';
  if (!_.isEmpty(contentType)) {
    if (contentType.match(/json/)) {
      mode = 'json';
    } else if (contentType.match(/xml/)) {
      mode = 'xml';
    } else if (contentType.match(/html/)) {
      // mode = 'html'; // we don't have the html ace editor stuff loaded right now
    }
  }

  let bodyEditorContent;
  if (raw) {
    bodyEditorContent = (
      <FormCodeEditor
        id={id}
        readOnly={readOnly}
        input={{
          value: input.body || '',
          onChange(value) {
            handleUpdate('set', ['input', 'body'], value);
          },
        }}
        mode={mode}
      />
    );
  } else {
    bodyEditorContent = (
      <FormInputMap
        id={id}
        fields={typeof input.body === 'object' ? input.body : {}}
        handleUpdate={(t, p, v) => {
          handleUpdate(t, ['input', 'body'].concat(p), v);
        }}
        addText="Add Property"
        namePlaceholder="param name"
        valuePlaceholder="param value"
        readOnly={readOnly}
        size="tiny"
      />
    );
  }

  return (
    <div className={`FormHttpBodyEditor ${className}`}>
      <div className="flex mb-6 items-center">
        <FormDropdown
          className="mr-6"
          text={contentType || 'no content type'}
          button
          basic
          disabled={readOnly}
          size="tiny"
          options={[
            { text: 'no content type', value: null },
            { text: 'Text (text/plain)', value: 'text/plain' },
            { text: 'JSON (application/json)', value: 'application/json' },
            { text: 'Javascript (application/javascript)', value: 'application/javascript' },
            { text: 'XML (application/xml)', value: 'application/xml' },
            { text: 'XML (text/xml)', value: 'text/xml' },
            { text: 'HTML (text/html)', value: 'text/html' },
          ]}
          onChange={(e, { value }) => {
            handleUpdateContentType(handleUpdate, input.headers, value);
          }}
        />

        <div className="mr-6">
          <FormRadio
            label="raw"
            labelProps={{ className: 'text-sm font-normal' }}
            name="contentType"
            value=""
            checked={raw}
            onChange={() => {
              handleUpdateContentType(handleUpdate, input.headers, '');
            }}
            disabled={readOnly}
          />
        </div>

        <div className="mr-6">
          <FormRadio
            label="x-www-form-urlencoded"
            labelProps={{ className: 'text-sm font-normal' }}
            name="contentType"
            value="application/x-www-form-urlencoded"
            checked={contentType === 'application/x-www-form-urlencoded'}
            onChange={() => {
              handleUpdateContentType(
                handleUpdate,
                input.headers,
                'application/x-www-form-urlencoded'
              );
            }}
            disabled={readOnly}
          />
        </div>

        <div className="mr-6">
          <FormRadio
            label="form-data"
            labelProps={{ className: 'text-sm font-normal' }}
            name="contentType"
            value="multipart/form-data"
            checked={contentType === 'multipart/form-data'}
            onChange={() => {
              handleUpdateContentType(handleUpdate, input.headers, 'multipart/form-data');
            }}
            disabled={readOnly}
          />
        </div>
      </div>

      {bodyEditorContent}
    </div>
  );
};

export default FormHttpBodyEditor;
