import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import _ from 'lodash';

import DocumentContext from './documentContext';

class Frame extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    style: PropTypes.object,
    head: PropTypes.node,
  };

  componentDidMount() {
    this.renderFrameContents();
  }

  renderFrameContents = () => {
    const doc = ReactDOM.findDOMNode(this).contentDocument;

    if (doc && doc.readyState === 'complete') {
      const win = doc.defaultView || doc.parentView;

      const contents = (
        <DocumentContext document={doc} window={win}>
          <div className="frame-content">
            {this.props.head}
            {this.props.children}
          </div>
        </DocumentContext>
      );

      ReactDOM.render(contents, doc.body);
    } else {
      setTimeout(this.renderFrameContents, 0);
    }
  };

  componentDidUpdate() {
    this.renderFrameContents();
  }

  componentWillUnmount() {
    ReactDOM.unmountComponentAtNode(ReactDOM.findDOMNode(this).contentDocument.body);
  }

  shouldComponentUpdate(nextProps) {
    return this.props.id !== nextProps.id;
  }

  render() {
    const targetProps = _.clone(this.props);
    delete targetProps.children;

    // The iframe isn't ready so we drop children from props here
    return <iframe {...targetProps} />;
  }
}

export default Frame;
